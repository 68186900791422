/* Fonts */
@import url('https://fonts.bunny.net/css?family=Nunito');

/* Typography */
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

ul {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

#leftside {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

a.myactive {
  font-weight: bold;
  background-color: whitesmoke;
}
.cart_btn {
  color: #fff;
  background-color: #50826f;
  padding: 10px 50px;
  border: none;
  transition: .3s;
}
.cart_btn:hover {
  opacity: .8;
  transition: .3s;
}
.fixed_header {
  width: 100%;
  background-color: #fff;
}
.shop_logo {
  width: 250px;
  transition: .3s;
  @media (max-width: 980px) {
    width: 190px;
  }
}
.shop_logo:hover {
  opacity: .8;
  transition: .3s;
}
.tel {
  font-size: 2rem;
}
.only_pc {
  @media (max-width: 991px) {
    display: none;
  }
}
.footer {
  background-color: #000333;
  padding-top: 30px;
  padding-bottom: 10px;
  margin-top: 50px;
}
.footer_logo {
  width: 200px;
  padding-top: 10px;
}
.footer p {
  color: #fff;
}
.footer_list {
  display: flex;
  @media (max-width: 991px) {
    display: none;
  }
}
.footer_list, .footer_left {
  @media (max-width: 630px) {
    padding: 0 25px;
  }
}
.footer_list a {
  color: #fff;
  text-decoration: none;
  position: relative;
  transition: .3s;
}
.footer_list a:hover {
  color: #afe638;
  transition: .3s;
}
.footer_list a::before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: -20px;
  width: 12px;
  height: 1px;
  border-radius: 2px;
  background-color: #fff;
  transition: background-color 0.2s linear;
}
.footer_list li {
  padding: 10px 20px;
}
.copyright {
  font-size: 12px;
  color: #fff;
  padding-top: 40px;
}

.top_image {
  width: 91%;
  margin-top: 50px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 15px;
  }
}
h5 {
  font-size: 1.875rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }

}
.husqvarna {
  border-right: 1px solid #939393;
  @media (max-width: 991px) {
    border-right: none;
  }
}
.husqvarna .btn:hover {
  background-color: midnightblue;
  color: #fff;
}
.oregon .btn:hover {
  background-color: #c8102e;
  color: #fff;
}
.side_list li {
  padding: 5px 0;
  border-bottom: 1px solid #555;
}
.news {
  justify-content: space-between;
}
.news img {
  width: 64px;
  height: 64px;
  display: block;
  margin: 0 auto;
}
.list-group {
  border-radius: 0;
  list-style-type: none;
}
.list-group a {
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 1.2rem 0 1.2rem 1.2rem;
}
.category {
  padding: 1.2rem 0 1.2rem 1.2rem;
  background-color: #000333;
  color: #fff;
}
.category_m {
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

#pcgokan .compatibility, . {
  font-size: 2.5rem;
  font-weight: bold;
  border-left: 5px solid #2757a3;
}
.table_wrap {
  overflow-x: scroll;
}
.table {
  width: 2600px;
  border-collapse: collapse;
  white-space: nowrap;
}
.table img {
  width: 200px;
  @media (max-width: 980px) {
    width: 100px;
  }
}
.table th {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: #5274aa;
  color: #fff;
}
.table th, .table td {
  text-align: center;
  padding: 20px;
  vertical-align: middle;
  @media (max-width: 980px) {
    padding: 10px;
  }
}
.bg {
  background-color: #d6e2e8;
}
.border_r {
  border-right: 1px solid #707677;
  border-bottom: 1px solid #707677;
}
.border_l {
  border-left: 1px solid #707677;
}
.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #2757a3;
  border-radius: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: #e0e0e0;
  border-radius: 6px;
}
.link {
  transition: .3s;
}
.link:hover {
  background-color: #adc0de;
  border-radius: 5px;
  color: #fff;
  transition: .3s;
}

#smgokan .compatibility {
  font-size: 1.5rem;
  font-weight: bold;
  border-left: 5px solid #2757a3;
}
.smgokan_table {
  width: 100%;
  text-align: center;
}
.smgokan_table button {
  width: 100%;
  display: block;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 50px;
  background-color: #fff;
}
.gokan_unit {
  background-color: #5274aa;
  border-radius: 10px;
  margin: 40px 0;
  padding: 35px 0;
}
.gokan_unit ul {
  margin: 0 10px;
  padding: 0;
}
.gokan_unit ul li {
  padding: 10px 0;
}
.gokan_unit p {
  color: #fff;
  line-height: 0.7;
}
.info {
  font-size: 2.5rem;
  font-weight: bold;
  border-left: 5px solid #2757a3;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}
h3 {
  font-size: 20px;
  background-color:#2757a3;
  border-radius: 10px;
  color: #fff;
  background: rgb(143,176,219);
  background: linear-gradient(128deg, rgba(143,176,219,1) 0%, rgba(6,135,184,1) 0%, rgba(1,48,113,1) 100%);
}
h4 {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  background-color: #d6e2e8;
  padding: 5px 10px;
}
.info_text {
  color: #ec6142;
}
.link_gokan {
  width: 400px;
  display: inline-block;
  border: 2px solid #2757a3;
  border-radius: 10px;
  text-decoration: none;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  color: #2b281e;
  transition: .3s;
  @media (max-width: 980px) {
    width: 100%;
  }
}
.link_gokan:hover {
  color: #1E4DC6;
  transition: .3s;
}
.guide {
  font-size: 2.5rem;
  font-weight: bold;
  border-left: 5px solid #2757a3;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}
.step {
  width: 100%;
  color: #fff;
  border-radius: 10px;
  background: rgb(143,176,219);
  background: linear-gradient(128deg, rgba(143,176,219,1) 0%, rgba(6,135,184,1) 0%, rgba(1,48,113,1) 100%);
}
.vertical_line {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.vertical_line::before, .vertical_line::after {
  content: "";
  height: 4px;
  flex-grow: 1;
  background-color: #b8bcc3;
}
.vertical_line::before {
  margin-right: 1rem;
}
.vertical_line::after {
  margin-left: 1rem;
}
.check_mark {
  width: 30px;
  vertical-align: middle;
}
.engraving p {
  display: inline;
  vertical-align: middle;
}
.next {
  text-align: center;
  padding: 30px 0;
}
.finish {
  border: 3px solid black;
  border-radius: 10px;
  text-align: center;
  padding: 30px 0;
}
.chain {
  margin-top: 100px;
}
.blade, .chain_btn {
  width: 100%;
  padding: 20px 0;
  border: 2px solid #2757a3;
  margin-bottom: 40px;
  font-weight: bold;
}
.compatibility {
  padding-top: 30px;
  text-decoration: underline;
  transition: .3s;
}
.compatibility:hover {
  color: #B79862;
  transition: .3s;
}
.tekigo-table {
  width: 100%;
}
.tekigo-table thead tr {
  background-color: #d6e2e8;
}
.tekigo-table tbody tr th, .tekigo-table tbody tr td {
  padding: 15px 10px;
  border-bottom: 1px solid #ddd;
}
.tekigo-table tbody tr th {
  background-color: #5274aa;
  color: #fff;
}
.tekigo-table tbody tr td {
  border: 1px solid #ccdee7;
}
.modal-header {
  border-bottom: 2px solid #2757a3;
}
/* .tekigo_table {
  font-size: 2.5rem;
  font-weight: bold;
  border-left: 5px solid #2757a3;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
} */
.btn {
  background-color: orange;
  border: 1px solid #2757a3;
  margin-bottom: 50px;
}
::placeholder {
  @media (max-width: 980px) {
    font-size: 0.750rem;
  }
}
.gokan_title {
  font-size: 2.5rem;
  font-weight: bold;
  border-left: 5px solid #2757a3;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

a {
  color: #2b281e;
  transition: .3s;
}
a:hover {
  opacity: .8;
  transition: .3s;
}
.list {
  @media (max-width: 980px) {
    justify-content: space-around;
  }
}
.list_item {
  width: calc(100% /3 - 24px);
  padding: 0;
  margin: 0 12px;
  @media (max-width: 980px) {
    width: calc(50% - 24px);
  }
}
.tekigo-title {
  font-size: 2.5rem;
  font-weight: bold;
  border-left: 5px solid #2757a3;
}
.search, .btn {
  background-color: #fff;
  border: 1px solid #2757a3;
  margin-bottom: 50px;
}
.common-btn:hover {
  background-color: #2757a3;
  color: #fff;
}
.bar_lists {
  list-style: none;
}


.sawtype-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sawtype-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #2757a3;
}

.sawtype-modal-body {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 300px;
}

.sawtype-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.sawtype-table {
  width: 100%;
}
.sawtype-table tbody tr th, .sawtype-table tbody tr td {
  padding: 15px 10px;
  border-bottom: 1px solid #ddd;
}
.sawtype-table tbody tr th {
  background-color: #5274aa;
  color: #fff;
}
.sawtype-table tbody tr td {
  border: 1px solid #ccdee7;
}

.shohin-img {
  width: 190px;
  @media (max-width: 500px) {
    width: 100%;
  }
}